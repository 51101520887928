
/**
 * Logs an error in the console.
 *  Note: it does not generate a JavaScript error, so make sure to also generate an error if needed.
 * @param message
 */
export function logConsoleError(message) {
    // eslint-disable-next-line no-console
    console.error(message);
}

/**
 * Logs a warning in the console.
 * @param message
 */
export function logConsoleWarn(message) {
    // eslint-disable-next-line no-console
    console.warn(message);
}