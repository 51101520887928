import { isObject } from './types';

/**
 * @param {object} object
 * @returns
 */
export function isObjectEmpty(object) {
  for (let prop in object) {
    if (Object.hasOwnProperty.call(object, prop)) {
      return false;
    }
  }
  return true;
}

/**
 * @template T
 * @param {T[]} arr
 * @returns {T[]}
 */
export function removeEmptyElementsFromTheArrayRight(arr) {
    const newArr = arr.slice();
  
    while (newArr.length > 0) {
      const lastElement = newArr[newArr.length - 1];
  
      if (lastElement === '' || (isObject(lastElement) && isObjectEmpty(lastElement))) {
        newArr.pop();
      } else {
        return newArr;
      }
    }
  
    return newArr;
  }